<template>
  <div class="category-config mt-4" v-if="appliedCategories">
    <div>
      <div class="d-flex justify-content-between align-items-center mb-5">
        <b-row class="w-75">
          <b-col align-self="center"
            ><p class="font-weight-bold mb-0">Danh sách danh mục sản phẩm:</p></b-col
          >
          <!-- <b-col cols="3">
            <b-input
              size="sm"
              placeholder="Tìm kiếm theo tên"
              v-model="searchName"
              v-on:keyup.enter="fetchData"
              autocomplete="off"
            />
          </b-col>
          <b-col cols="3">
            <b-input
              size="sm"
              placeholder="Tìm kiếm theo mã"
              v-model="searchCode"
              v-on:keyup.enter="fetchData"
              autocomplete="off"
            />
          </b-col>
          <b-col cols="1" class="w-25">
            <b-button
              size="sm"
              variant="primary"
              @click="onFilter"
              class="font-weight-bolder"
            >
              <span class="px-4">Lọc</span></b-button
            >
          </b-col> -->
        </b-row>
        <b-button
          size="sm"
          variant="primary"
          class="mr-left font-weight-bolder"
          @click="showModalUpsertCate('add')"
        >
          <i class="flaticon2-add-1 icon-size-1-rem"></i>
          Tạo mới
        </b-button>
      </div>

      <div>
        <b-table
          :items="appliedCategories"
          :fields="categoryFields"
          bordered
          hover
          show-empty
        >
          <template #empty>
            <div
              class="d-flex flex-column aligh-items-center justify-content-center text-center"
            >
              <img
                class="text-center img-fluild mx-auto mb-1"
                src="@/assets/image/no-results.png"
              />
              <h6>Chưa có dữ liệu về danh mục</h6>
            </div>
          </template>
          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template v-slot:cell(categoryName)="row">
            <div v-html="formatCellByLevel(row.item, 'categoryName')"></div>
          </template>
          <template v-slot:cell(categoryCode)="row">
            <div v-html="formatCellByLevel(row.item, 'categoryCode')"></div>
          </template>
          <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-center">
              <b-dropdown size="sm" no-caret right lazy>
                <template slot="button-content">
                  <i class="flaticon2-settings icon-size-1-rem pr-0"></i>
                </template>
                <b-dropdown-item
                  @click="showModalUpsertCate('update', row.item)"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i class="flaticon2-pen icon-size-1-rem"></i>
                    &nbsp; Chỉnh sửa
                  </span>
                </b-dropdown-item>

                <b-dropdown-item
                  v-show="row.item.categoryLevel < 2"
                  @click="showModalUpsertCate('add-child', row.item)"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i
                      style="color: #1bc5bc"
                      class="fas fa-plus icon-size-1-rem"
                    ></i>
                    &nbsp; Thêm danh mục con
                  </span>
                </b-dropdown-item>
                <b-dropdown-item @click="showDeleteAlert(row.item)">
                  <span style="color: #3f4254; font-size: 12px">
                    <i
                      style="color: #d33"
                      class="
                          flaticon2-rubbish-bin-delete-button
                          icon-size-1-rem
                        "
                    ></i>
                    &nbsp; Xóa
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>

        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng số:
              {{ formatPrice(appliedCategories.length) }}
            </p>
          </b-col>
        </b-row>
      </div>
    </div>
    <b-modal
      ref="upsert-category-modal"
      hide-footer
      :title="bindingTitleModal()"
    >
      <b-form-group v-if="!isHidden">
        <label>Danh mục cha:</label>
        <Autosuggest
          :disabled="isDisabled"
          :model="searchCategories"
          :suggestions="filteredCategoryOptions"
          placeholder="danh mục sản phẩm"
          @select="onSelectedCategory"
          @change="onInputCateChange"
          suggestionName="convertedName"
        />
      </b-form-group>
      <b-form-group>
        <label>Tên danh mục:</label>
        <span class="text-danger">*</span>
        <b-form-input
          trim
          size="sm"
          placeholder="Nhập tên danh mục"
          v-model="mainModel.categoryName"
          :state="validateState('categoryName')"
        ></b-form-input>
        <b-form-invalid-feedback v-if="!$v.mainModel.categoryName.required"
          >Yêu cầu nhập tên danh mục</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group>
        <label>Mã danh mục:</label>
        <span class="text-danger">*</span>
        <b-form-input
          trim
          size="sm"
          placeholder="Nhập mã danh mục"
          v-model="mainModel.categoryCode"
          :state="validateState('categoryCode')"
        ></b-form-input>
        <b-form-invalid-feedback v-if="!$v.mainModel.categoryCode.required"
          >Yêu cầu nhập mã danh mục</b-form-invalid-feedback
        >
      </b-form-group>

      <b-button
        size="sm"
        variant="primary"
        class="mr-3"
        style="width: 70px"
        @click="handleUpsertCategory"
        >Lưu</b-button
      >
      <b-button
        size="sm"
        variant="secondary"
        style="width: 70px"
        @click="hideModalCategory"
        >Hủy</b-button
      >
    </b-modal>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { v4 } from 'uuid';

import { removeAccents, formatPrice } from '@/utils/common';

import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';

export default {
  props: ['appliedCategories', 'partnerApiId'],
  mixins: [validationMixin],
  validations: {
    mainModel: {
      categoryName: {
        required
      },
      categoryCode: {
        required
      }
    }
  },
  data() {
    return {
      action: '',
      isHidden: false,
      isDisabled: false,
      searchName: '',
      searchCode: '',
      searchCategories: '',
      defaultCategoriesModel: {
        categoryCode: '',
        categoryId: null,
        categoryLevel: 0,
        categoryName: '',
        categoryParentId: null,
        partnerApiId: this.partnerApiId,
        type: 3
      },
      mainModel: { ...this.defaultCategoriesModel },
      categoryFields: [
        {
          label: 'STT',
          key: 'index',
          tdClass: 'text-center',
          thStyle: { width: '5%' }
        },
        {
          key: 'categoryName',
          label: 'Tên danh mục',
          tdClass: 'nameCateClass',
          thClass: 'nameCateClass',
          thStyle: { width: '50%' }
        },
        {
          key: 'categoryCode',
          label: 'Mã danh mục',
          tdClass: 'codeCateClass',
          thClass: 'codeCateClass',
          thStyle: { width: '45%' }
        },
        { key: 'actions', label: '' }
      ],

      categoryOptions: [],
      filteredCategoryOptions: []
    };
  },
  created() {},
  watch: {
    appliedCategories: {
      handler(appliedCategories) {
        const categories = appliedCategories || []
        this.composeCategoriesByLevel([...categories]);
      },
      immediate: true,
      deep: true
    }
  },
  computed: {},
  components: {
    Autosuggest
  },
  mounted() {
    this.$nextTick(function() {
      this.bindingTitleModal();
    });
  },
  methods: {
    formatPrice,
    bindingTitleModal() {
      const categoryParentId = this.mainModel.categoryParentId;
      const categoryLevel = this.mainModel.categoryLevel;

      const categoryName = this.mainModel.categoryName;

      if (!categoryParentId) return 'Thêm mới danh mục';
      if (!categoryLevel) return `Thêm mới danh mục con cho #${categoryName}`;

      return `Cập nhật danh mục #${categoryName}`;
    },
    composeCategoriesByLevel(appliedCategories) {
      const categories = [...appliedCategories];
      const convertedNameCategories = categories.map(category => {
        const categoryNamePharses = {
          1: '-',
          2: '--',
          3: '---',
          4: '----',
          5: '-----'
        };
        const prefixCategoryName =
          categoryNamePharses[Number(category.categoryLevel)] || '';

        return {
          ...category,
          convertedName: `${prefixCategoryName} ${category.categoryName}`
        };
      });
      this.categoryOptions = [].concat(convertedNameCategories);
      this.filteredCategoryOptions = [...this.categoryOptions];
    },
    onSelectedCategory(option) {
      this.mainModel.categoryParentId = option.item.categoryId;
      this.mainModel.categoryLevel = option.item.categoryLevel;
    },
    onInputCateChange(text) {
      this.mainModel.categoryParentId = null;
      this.searchCategories = text;

      const categoryOptions = [...this.categoryOptions];

      const filteredData = categoryOptions.filter(item => {
        return (
          removeAccents(item.categoryName)
            .toLowerCase()
            .indexOf(removeAccents(text.toLowerCase())) > -1
        );
      });

      this.filteredCategoryOptions = [...filteredData];
    },
    deleteCategory(item) {
      const action = 'delete';

      const category = { action, ...item };
      this.$emit('onUpsertAppliedCategories', category);
    },
    showModalUpsertCate(action, item = null) {
      this.isHidden = false;
      this.isDisabled = false;
      this.searchCategories = item ? item.categoryName : '';
      this.action = action;

      let category = {};
      const newCategoryId = v4();

      switch (action) {
        case 'update': {
          this.isHidden = true;
          category = Object.assign({}, item);
          break;
        }
        case 'add-child': {
          const childCategory = {
            ...this.defaultCategoriesModel,
            categoryId: newCategoryId,
            categoryParentId: item.categoryId,
            categoryLevel: item.categoryLevel
          };
          category = Object.assign({}, childCategory);
          this.isDisabled = true;
          break;
        }
        default: {
          const newCategory = {
            ...this.defaultCategoriesModel,
            categoryId: newCategoryId
          };
          category = Object.assign({}, newCategory);
          break;
        }
      }

      this.mainModel = Object.assign({}, category);
      this.$refs['upsert-category-modal'].show();
    },
    handleUpsertCategory() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      let action = this.action;

      if (action === 'add' && this.mainModel.categoryParentId) {
        action = 'add-child';
      }

      const category = {
        ...this.mainModel,
        action
      };

      this.$emit('onUpsertAppliedCategories', category);
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.hideModalCategory();
    },

    formatCellByLevel(category, field) {
      let tagHtml = '';

      switch (category.categoryLevel) {
        case 0:
          tagHtml = `<p >${category[field]}</p>`;
          break;
        case 1:
          tagHtml = `<p class="ml-10">${category[field]}</p>`;
          break;
        case 2:
          tagHtml = `<p class="ml-20">${category[field]}</p>`;
          break;
        case 3:
          tagHtml = `<p class="ml-30">${category[field]}</p>`;
          break;
        case 4:
          tagHtml = `<p class="ml-40">${category[field]}</p>`;
          break;
        case 5:
          tagHtml = `<p class="ml-50">${category[field]}</p>`;
          break;
        default:
          break;
      }
      return tagHtml;
    },
    onFilter() {
      // this.fetchData();
    },
    showDeleteAlert(item) {
      Swal.fire({
        title: 'Xóa danh mục!',
        text: 'Bạn có chắc muốn xóa danh mục này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true
      }).then(result => {
        if (result.value) {
          this.deleteCategory(item);
        }
      });
    },
    hideModalCategory() {
      this.$refs['upsert-category-modal'].hide();
    },
    hideModalMoveTreeCate() {
      this.$refs['merge-tree-cate-modal'].hide();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.mainModel[name];
      return $dirty ? !$error : null;
    }
  }
};
</script>

<style lang="scss">
.category-config {
  .icon-size-1-rem {
    font-size: 1rem !important;
  }
  .icon {
    padding-top: 7px;
  }
  .ml-10 {
    margin-left: 5px;
  }
  .ml-20 {
    margin-left: 10px;
  }
  .ml-30 {
    margin-left: 15px;
  }
  .ml-40 {
    margin-left: 20px;
  }
  .ml-50 {
    margin-left: 25px;
  }
  .nameCateClass {
    width: 45%;
  }
  .codeCateClass {
    width: 45%;
  }
  .icon:hover {
    background-color: #90c6fc;
  }
}
</style>

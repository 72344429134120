<template>
  <div class="add-installment-program">
    <KTCodePreview v-bind:title="bindingTitle">
      <template v-slot:preview>
        <b-form ref="form" lazy-validation class="row">
          <b-container class="bv-example-row">
            <b-row>
              <b-col cols="4">
                <b-form-group class="required-control">
                  <label class="font-weight-bold">Tên partner: </label>
                  <b-form-input
                    size="sm"
                    v-model="$v.apiParams.name.$model"
                    placeholder="Nhập tên partner"
                    autocomplete="off"
                    :state="validateState('name')"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    >Vui lòng nhập tên partner</b-form-invalid-feedback
                  >
                </b-form-group>
                <b-form-group>
                  <label class="font-weight-bold"
                    >Chọn cửa hàng:
                    <span class="font-weight-bold text-danger">*</span></label
                  >
                  <treeselect
                    valueFormat="object"
                    :normalizer="normalizer"
                    :options="store.options"
                    v-model="$v.apiParams.appliedStores.$model"
                    :multiple="true"
                    :backspace-removes="false"
                    placeholder="Chọn cửa hàng"
                    :max-height="200"
                    noResultsText="Không tìm thấy kết quả"
                    retryTitle="Thử lại"
                    :state="validateState('appliedStores')"
                  ></treeselect>
                  <b-form-invalid-feedback
                    v-if="!$v.apiParams.appliedStores.minLength"
                    >Vui lòng nhập cửa hàng</b-form-invalid-feedback
                  >
                </b-form-group>
              </b-col>

              <b-col cols="5">
                <b-form-group>
                  <label class="font-weight-bold">Mô tả:</label>
                  <b-form-textarea
                    class="input-style"
                    v-model="apiParams.description"
                    placeholder="Nhập mô tả"
                    rows="5"
                    autocomplete="off"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
              <b-col cols="3">
                <b-form-group class="required-control">
                  <label class="font-weight-bold">Trạng thái: </label>
                  <b-form-select
                    size="sm"
                    class="select-style"
                    v-model="apiParams.status"
                    :options="[
                      {
                        id: 1,
                        name: 'Hoạt động',
                      },
                      {
                        id: 2,
                        name: 'Ngừng hoạt động',
                      },
                    ]"
                    value-field="id"
                    text-field="name"
                    autocomplete="off"
                  ></b-form-select>
                </b-form-group>
              </b-col>
            </b-row>

            <div class="my-5"></div>
            <hr
              class="hr-text"
              data-content="Cấu hình sản phẩm"
              style="font-weight: 600"
            />
            <b-row>
              <b-form-group class="col-md-12 pr-0">
                <label class="font-weight-bold">Tìm sản phẩm:</label>
                <b-col md="12" class="row pr-0">
                  <b-col md="6" class="p-0">
                    <Autosuggest
                      :model="product.searchQuery"
                      :suggestions="product.suggestions"
                      :placeholder="'tên sản phẩm'"
                      :limit="10"
                      @select="onSelected($event, 'product')"
                      @change="onChange($event, 'product')"
                      suggestionName="productName"
                    />
                  </b-col>
                  <b-col md="2" class="p-0">
                    <b-form-select
                      class="select-style border-left-0 rounded-left-0 rounded-right-0"
                      v-model="selectTypeSearchFor"
                      :options="product.types"
                      size="sm"
                      value-field="id"
                      text-field="name"
                      disabled-field="notEnabled"
                      @change="debounceInputFor"
                    ></b-form-select>
                  </b-col>
                  <b-col md="2" class="p-0">
                    <b-form-select
                      class="select-style border-left-0 rounded-left-0"
                      v-model="selectTypeOfProductFor"
                      :options="product.relationshipTypes"
                      size="sm"
                      value-field="id"
                      text-field="name"
                      disabled-field="notEnabled"
                      @change="debounceInputFor"
                    ></b-form-select>
                  </b-col>
                  <b-col md="2" class="pr-0">
                    <div class="d-flex justify-content-end">
                      <button
                        type="button"
                        class="btn btn-secondary font-weight-bold btn-sm"
                        @click="showModalImportProductFor"
                      >
                        <i
                          style="font-size: 1rem"
                          class="far fa-file-excel"
                        ></i>
                        Nhập từ excel
                      </button>
                    </div>
                  </b-col>
                </b-col>
              </b-form-group>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-table
                  bordered
                  hover
                  :fields="product.tableFeilds"
                  :items="apiParams.appliedProducts"
                  :per-page="10"
                  :current-page="currentPageProductTable"
                  v-if="apiParams.appliedProducts"
                >
                  <template v-slot:cell(productId)="row">
                    <span class="font-weight-bold">
                      {{ row.item.productId }}
                    </span>
                  </template>
                  <template v-slot:cell(productName)="row">
                    <span
                      class="font-weight-bold"
                      v-text="row.item.productName"
                    ></span>
                    <b-row>
                      <b-col class="text-center">
                        <i class="fas fa-level-up-alt fa-rotate-90 pl-1"></i>
                      </b-col>
                      <b-col cols="10" class="pl-0">
                        <b-form-group class="required-control">
                          <b-form-input
                            class="mt-1"
                            size="sm"
                            v-model="
                              apiParams.appliedProducts[row.index]
                                .productNameReplacement
                            "
                            :state="validateState('productNameReplacement')"
                            placeholder="Nhập tên sản phẩm"
                            autocomplete="off"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </template>

                  <template v-slot:cell(productPrice)="row">
                    <span
                      class="font-weight-bold"
                      v-text="convertPrice(row.item.productPrice)"
                    ></span>
                    <b-row>
                      <b-col class="text-center">
                        <i class="fas fa-level-up-alt fa-rotate-90 pl-1"></i>
                      </b-col>
                      <b-col cols="10" class="pl-0">
                        <b-form-group class="required-control">
                          <b-form-input
                            class="mt-1"
                            size="sm"
                            v-model="
                              apiParams.appliedProducts[row.index]
                                .productPriceReplacement
                            "
                            v-mask="mask"
                            :state="validateState('productPriceReplacement')"
                            placeholder="Nhập giá sản phẩm"
                            autocomplete="off"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </template>
                  <template v-slot:cell(productCategory)="row">
                    <Autosuggest
                      :model="apiParams.appliedProducts[row.index].categoryName"
                      :suggestions="category.filteredOptions"
                      :limit="10"
                      placeholder="danh mục sản phẩm"
                      suggestionName="convertedName"
                      @select="onSelected($event, 'category', row.index)"
                      @change="onChange($event, 'category')"
                    />
                  </template>
                  <template v-slot:cell(actions)="row">
                    <v-icon
                      small
                      class="text-danger"
                      @click="showDeleteProductAlert(row.item)"
                      v-b-tooltip
                      title="Xóa"
                      >mdi-delete</v-icon
                    >
                  </template>
                </b-table>
                <p class="font-weight-bold pl-1">
                  Tổng số :
                  {{ convertPrice(appliedProductCount) }}
                </p>
                <b-pagination
                  v-show="hasPagination"
                  v-model="currentPageProductTable"
                  :total-rows="appliedProductCount"
                  :per-page="10"
                  align="right"
                ></b-pagination>
              </b-col>
            </b-row>

            <div class="my-5"></div>
            <hr
              class="hr-text"
              data-content="Cấu hình danh mục sản phẩm"
              style="font-weight: 600"
            />
            <CategoryConfig
              :appliedCategories="apiParams.appliedCategories"
              :partnerApiId="partnerApiId"
              @onUpsertAppliedCategories="handleUpsertAppliedCategories"
            />
          </b-container>
        </b-form>
      </template>
      <template v-slot:foot>
        <b-container class="bv-example-row">
          <b-row>
            <b-overlay
              :show="isBusy"
              opacity="0.6"
              spinner-variant="white"
              spinner-type="grow"
              spinner-small
            >
              <b-button
                style="fontweight: 600; width: 70px"
                variant="primary"
                size="sm"
                type="submit"
                @click="upsertPartnerApi"
                >Lưu</b-button
              >
            </b-overlay>

            <b-button
              style="margin-left: 10px; font-weight: 600; width: 70px"
              variant="secondary"
              size="sm"
              @click="onBack"
              >Hủy</b-button
            >
          </b-row>
        </b-container>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';
import decounce from 'debounce';

import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';

import { getToastInstance } from '@/utils/toastHelper';
import {
  TIME_TRIGGER,
  PRODUCT_TYPES,
  PRODUCT_RELATIONSHIP_TYPES,
} from '@/utils/constants';
import {
  currencyMask,
  makeToastFaile,
  unMaskPrice,
  makeToastSuccess,
  convertPrice,
  removeAccents,
} from '@/utils/common';
import { PARTNER_API_DETAIL_TYPE } from '@/utils/enum';

import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import KTCodePreview from '@/view/content/CodePreview.vue';
import CategoryConfig from '@/view/components/web-partners/WebPartnerUpsertCategoryConfig.vue';
import Treeselect from '@riophae/vue-treeselect';

import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
  props: ['partnerApiId'],
  mixins: [validationMixin],
  validations: {
    apiParams: {
      productPriceReplacement: {
        required,
      },
      productNameReplacement: {
        required,
      },
      name: {
        required,
      },
      partnerApiName: {
        required,
      },
      appliedStores: {
        required,
        minLength: minLength(1),
      },
    },
  },
  components: {
    KTCodePreview,
    CategoryConfig,
    Autosuggest,
    Treeselect,
  },
  data() {
    return {
      mask: currencyMask,
      initDataApiParams: {
        id: null,
        name: '',
        description: '',
        status: 1,
        appliedStores: [],
        appliedProducts: [],
        appliedCategories: [],
      },
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
        };
      },
      defaultCategoriesModel: {
        categoryCode: '',
        categoryId: null,
        categoryLevel: 0,
        categoryName: '',
        categoryParentId: null,
      },
      convertedStores: [],
      apiParams: { ...this.initDataApiParams },
      product: {
        searchQuery: '',
        suggestions: [],
        types: PRODUCT_TYPES,
        relationshipTypes: PRODUCT_RELATIONSHIP_TYPES,
        tableFeilds: [
          {
            key: 'productId',
            label: 'Mã',
            thStyle: {
              fontWeight: 600,
              color: '#181c32',
              textAlign: 'center',
              width: '10%',
            },
            tdClass: 'text-center align-middle',
          },
          {
            key: 'productName',
            label: 'Tên sản phẩm',
            thStyle: {
              fontWeight: 600,
              color: '#181c32',
              textAlign: 'center',
              width: '35%',
            },
            tdClass: 'align-middle',
          },
          {
            key: 'productPrice',
            label: 'Giá bán',
            thStyle: {
              fontWeight: 600,
              color: '#181c32',
              textAlign: 'center',
              width: '25%',
            },
            tdClass: 'align-middle',
          },
          {
            key: 'productCategory',
            label: 'Danh mục sản phẩm',
            thStyle: {
              fontWeight: 600,
              color: '#181c32',
              textAlign: 'center',
              width: '25%',
            },
            tdClass: 'align-middle',
          },
          {
            key: 'actions',
            label: '',
            thStyle: {
              textAlign: 'center',
              width: '5%',
            },
            tdClass: 'text-center align-middle',
          },
        ],
      },
      store: {
        options: [],
      },
      category: {
        options: [],
        filteredOptions: [],
      },
      selectTypeSearchFor: 1,
      selectTypeOfProductFor: 0,
      currentPageProductTable: 1,
      isSearching: false,
      isBusy: false,
    };
  },
  created() {
    this.getStores();
  },
  watch: {
    partnerApiId: {
      async handler(partnerApiId) {
        const partnerApiModel = this.isEditMode
          ? await this.getPartnerApiById(partnerApiId)
          : this.initDataApiParams;

        this.apiParams = { ...partnerApiModel };
      },
      immediate: true,
      deep: true,
    },
    'apiParams.appliedCategories': {
      handler(appliedCategories) {
        const categories = appliedCategories || [];
        this.composeCategoriesByLevel([...categories]);
      },
      immediate: true,
      deep: true,
    },
    bindingTitle: {
      handler() {
        this.createBreakcrumb();
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    isEditMode() {
      return !!this.partnerApiId;
    },
    hasPagination() {
      const appliedProducts = this.apiParams.appliedProducts || [];
      const PRODUCT_COUNT = 10; // số lượng sản phẩm

      return appliedProducts.length > PRODUCT_COUNT;
    },
    appliedProductCount() {
      if (!this.apiParams.appliedProducts) return 0;
      return this.apiParams.appliedProducts.length;
    },
    getStoreSuggestions() {
      return [...this.convertedStores];
    },
    bindingTitle() {
      const suffixTitle = 'cấu hình web gateway';
      return !this.partnerApiId
        ? `Thêm mới ${suffixTitle}`
        : `Cập nhật ${suffixTitle}`;
    },
  },
  methods: {
    ...getToastInstance(),
    convertPrice,
    async getPartnerApiById(partnerApiId) {
      const getPartnerApiUrl = `/partner-apis/${partnerApiId}`;

      try {
        const response = await ApiService.get(getPartnerApiUrl);
        const partnerApi = { ...response.data.data };

        return this.transformPartnerApi(partnerApi);
      } catch (err) {
        makeToastFaile('Lỗi!');
      }
    },
    async getProductsBySearchType(params) {
      this.isSearching = true;
      this.product.suggestions = [];

      try {
        const getProductUrl = 'productSearch/search-by-type';
        const response = await ApiService.query(getProductUrl, { params });
        const products = [...response.data.data];

        this.product.suggestions = this.composeProductSuggestions(products);
      } catch (error) {
        makeToastFaile('Lỗi! Không tìm thấy sản phẩm');
      } finally {
        this.isSearching = false;
      }
    },
    async getStores() {
      try {
        const getStoresUrl = '/stores/getStores';
        const response = await ApiService.get(getStoresUrl);
        const stores = [...response.data.data];

        this.store.options = stores;
      } catch (error) {
        makeToastFaile('Lỗi! Không tìm thấy cửa hàng');
      }
    },
    async upsertPartnerApi() {
      this.isBusy = true;

      // @TODO: validate data to upsert partner api
      const params = this.getDataUpsert();

      let method = 'post';
      if (this.partnerApiId) {
        params.id = Number(this.partnerApiId) || null;
        method = 'put';
      }

      ApiService[method]('partner-apis', params)
        .then((response) => {
          this.isBusy = false;
          const { status, message } = response.data;
          if (status === 1) {
            makeToastSuccess(message);
            history.back();
          } else {
            makeToastFaile(message);
          }
        })
        .catch((err) => {
          this.isBusy = false;
          const message = err.response.data.message;
          makeToastFaile(message);
        });
    },
    handleUpsertAppliedCategories({ action, ...category }) {
      const appliedCategories = [...this.apiParams.appliedCategories];

      switch (action) {
        case 'add': {
          const newCategory = { ...category };

          appliedCategories.push(newCategory);
          this.apiParams.appliedCategories = [].concat(appliedCategories);
          break;
        }
        case 'update': {
          const indexCategory = appliedCategories.findIndex(
            (item) => item.categoryId === category.categoryId,
          );
          if (indexCategory > -1) {
            appliedCategories[indexCategory] = category;
            this.apiParams.appliedCategories = [].concat(appliedCategories);
          }
          break;
        }
        case 'add-child': {
          const indexParentCategory = appliedCategories.findIndex(
            (item) => item.categoryId === category.categoryParentId,
          );

          if (indexParentCategory > -1) {
            const newCategory = {
              ...category,
              categoryLevel: category.categoryLevel
                ? category.categoryLevel + 1
                : 1,
            };
            appliedCategories.splice(indexParentCategory + 1, 0, newCategory);
            this.apiParams.appliedCategories = [].concat(appliedCategories);
          }

          break;
        }
        case 'delete': {
          const indexCategory = appliedCategories.findIndex(
            (item) => item.categoryId === category.categoryId,
          );

          if (indexCategory > -1) {
            appliedCategories.splice(indexCategory, 1);
            this.apiParams.appliedCategories = [].concat(appliedCategories);
          }
          break;
        }
      }
    },
    insertCategoryAt(categories, index, newCategory) {
      const newCategories = [...categories];
      newCategories.splice(index, 0, newCategory);
      return newCategories;
    },
    getDataUpsert() {
      const params = { ...this.apiParams };
      const appliedCategories = params.appliedCategories;

      const appliedStores = params.appliedStores;
      const appliedProducts = params.appliedProducts;

      const removedMaskProducts = this.removeMaskPriceProducts(appliedProducts);
      const convertedStores = this.convertAppliedStores(appliedStores);

      const details = [
        ...removedMaskProducts,
        ...convertedStores,
        ...appliedCategories,
      ];

      return {
        ...params,
        details,
      };
    },
    convertAppliedStores(appliedStores) {
      if (!appliedStores || !appliedStores.length) return [];
      const stores = [...appliedStores];

      return stores.map((store) => ({
        ...store,
        partnerApiId: this.partnerApiId,
        storeId: store.id,
        storeName: store.name,
        type: PARTNER_API_DETAIL_TYPE.STORE,
      }));
    },
    removeMaskPriceProducts(appliedProducts) {
      if (!appliedProducts || !appliedProducts.length) return [];

      return appliedProducts.map((product) => {
        const DEFAULT_SELLING_PRICE = '-1';
        return {
          ...product,
          productPriceReplacement: product.productPriceReplacement
            ? unMaskPrice(product.productPriceReplacement)
            : DEFAULT_SELLING_PRICE,
        };
      });
    },
    composeProductSuggestions(products) {
      if (!products || !products.length) return [];

      const productOptions = [...products].map((item) => {
        return {
          partnerApiId: this.partnerApiId,
          productId: item.productId,
          productName: item.productName,
          productPrice: item.sellingPrice,
          type: PARTNER_API_DETAIL_TYPE.PRODUCT,
          productNameReplacement: '',
          productPriceReplacement: null,
        };
      });

      return productOptions;
    },
    transformPartnerApi(partnerApi) {
      const exactedPartnerApi = {
        id: partnerApi.id,
        name: partnerApi.name || '',
        description: partnerApi.description || '',
        status: partnerApi.status || 1,
      };
      const details = [...partnerApi.details];

      const appliedProducts = details
        .filter((detail) => detail.type === PARTNER_API_DETAIL_TYPE.PRODUCT)
        .map((product) => {
          const DEFAULT_SELLING_PRICE = '-1';
          const productPriceReplacement =
            product.productPriceReplacement !== DEFAULT_SELLING_PRICE
              ? product.productPriceReplacement
              : '';
          return {
            ...product,
            productPriceReplacement,
          };
        });

      const appliedStores = details
        .filter((detail) => detail.type === PARTNER_API_DETAIL_TYPE.STORE)
        .map((store) => ({
          ...store,
          id: store.storeId,
          label: store.storeName,
        }));

      const appliedCategories = details
        .filter((detail) => detail.type === PARTNER_API_DETAIL_TYPE.CATEGORY)
        .map((category) => ({
          partnerApiId: category.partnerApiId,
          categoryId: category.categoryId,
          categoryCode: category.categoryCode,
          categoryName: category.categoryName,
          categoryParentId: category.categoryParentId,
          categoryLevel: category.categoryLevel,
          type: category.type,
        }));

      return {
        ...exactedPartnerApi,
        appliedProducts,
        appliedStores,
        appliedCategories,
      };
    },
    composeCategoriesByLevel(appliedCategories) {
      const categories = [...appliedCategories];
      const convertedNameCategories = categories.map((category) => {
        const categoryNamePharses = {
          1: '-',
          2: '--',
          3: '---',
          4: '----',
          5: '-----',
        };
        const prefixCategoryName =
          categoryNamePharses[Number(category.categoryLevel)] || '';

        return {
          ...category,
          convertedName: `${prefixCategoryName} ${category.categoryName}`,
        };
      });
      this.category.options = [].concat(convertedNameCategories);
      this.category.filteredOptions = [...this.category.options];
    },
    showDeleteProductAlert(item) {
      Swal.fire({
        title: 'Xóa sản phẩm!',
        text: `Bạn có chắc muốn xóa ${item.productName} này không ?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      }).then(async (result) => {
        if (result.dismiss != 'cancel') {
          this.apiParams.appliedProducts = this.deleteItemByType(
            'product',
            item.productId,
          );
        }
      });
    },
    deleteItemByType(type, id) {
      const item = {
        product: {
          items: [...this.apiParams.appliedProducts],
          prop: 'productId',
        },
      };
      let newItems = null;
      const prop = item[type].prop;
      const items = item[type].items;

      if (items.length && prop) {
        const removedItems = items.filter((item) => item[prop] !== id);
        newItems = [...removedItems];
      }

      return newItems || items;
    },

    onSelected(option, type, index = null) {
      this.searchCategory = '';

      this.product.searchQuery = '';
      const appliedProducts = [...this.apiParams.appliedProducts];
      switch (type) {
        case 'product': {
          const isExist =
            appliedProducts.findIndex(
              (product) => product.productId === option.item.productId,
            ) > -1;
          if (isExist) {
            return makeToastFaile(`${option.item.productName} đã tồn tại`);
          }

          const newProductOption = {
            partnerApiId: this.partnerApiId,
            type: PARTNER_API_DETAIL_TYPE.PRODUCT,
            productId: option.item.productId,
            productName: option.item.productName,
            productPrice: Number(option.item.productPrice) || 0,
            productNameReplacement: '',
            productPriceReplacement: null,
            categoryId: '',
            categoryCode: '',
            categoryName: '',
            categoryParentId: '',
            categoryLevel: null,
          };
          this.apiParams.appliedProducts.push(newProductOption);
          break;
        }
        case 'category': {
          const appliedProduct = appliedProducts[index];
          const appliedCategory = {
            ...option.item,
            type: PARTNER_API_DETAIL_TYPE.PRODUCT,
          };
          const combineProductWithCategory = Object.assign(
            {},
            appliedProduct,
            appliedCategory,
          );

          this.apiParams.appliedProducts[index] = combineProductWithCategory;
          break;
        }
      }
    },
    onChange(text, type, index = null) {
      const product = { ...this.apiParams.appliedProducts[index] };
      switch (type) {
        case 'product': {
          this.product.searchQuery = text;
          this.debounceInputFor();
          break;
        }
        case 'category': {
          if (!text || !text.trim() || !this.category.filteredOptions.length) {
            this.apiParams.appliedProducts[index] = Object.assign(
              {},
              product,
              this.defaultCategoriesModel,
            );
            return;
          }
          this.searchCategory = text;

          const categoryOptions = [...this.category.options];
          const filteredCategories = categoryOptions.filter((category) => {
            return (
              removeAccents(category.categoryName)
                .toLowerCase()
                .indexOf(removeAccents(text.toLowerCase())) > -1
            );
          });

          this.category.filteredOptions = [...filteredCategories];
          break;
        }
      }
    },
    onBack() {
      history.back();
    },
    debounceInputFor: decounce(function () {
      if (!this.isSearching) {
        const searchQuery = this.product.searchQuery
          ? this.product.searchQuery.trim()
          : null;
        const typeSearch = this.selectTypeSearchFor;
        const typeOfProduct = this.selectTypeOfProductFor;

        const params = {
          searchProduct: searchQuery,
          typeSearch,
          typeOfProduct,
        };
        this.getProductsBySearchType(params);
      }
    }, TIME_TRIGGER),
    showModalImportProductFor() {
      alert('Tính năng đang phát triển');
    },
    createBreakcrumb() {
      this.$store.dispatch(SET_BREADCRUMB, [{ title: this.bindingTitle }]);
    },
    validateState(name) {
      if (!name) return null;
      const { $dirty, $error } = this.$v.apiParams[name];
      return $dirty ? !$error : null;
    },
  },
};
</script>

<style>
.input-style {
  border: 1px solid #ced4da;
}
input.form-control {
  border: 1px solid #ced4da;
}
.muti-basic-custom .rounded {
  border-radius: 0.28rem !important;
}
.multi-select-wrapper {
  flex: 1 1 auto;
}

.center-label label {
  margin-bottom: 0px;
}

.select-style {
  border-radius: 0.28rem;
  box-shadow: none;
  border: 1px solid #ced4da;
}
</style>
